import { useAxios } from '@/hooks/web/useAxios'

const { request } = useAxios()

export const adminLoginApi = (data: any) => {
  return request({ url: '/java_api/pc2/auth/admin', method: 'post', data })
  // return request({ url: '/php_api/admin_user/login', method: 'post', data })
}

export const checkLoginApi = (data: any) => {
  return request({ url: '/java_api/pc2/auth/check_login', method: 'post', data })
}
