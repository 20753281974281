
import { Options, Vue } from 'vue-class-component'

import { adminLoginApi } from '@/api/auth'
import { useCache } from '@/hooks/web/useCache'

@Options({
  components: {
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    inputKeyDown (e: any) {
      if (e.code === 'Enter') {
        this.doLogin()
      }
    },
    doLogin () {
      const data = {
        username: this.username,
        password: this.password
      }

      adminLoginApi(data).then((res: any) => {
        if (res !== undefined) {
          const { wsCache } = useCache()

          wsCache.set('token', res.data.token)
          wsCache.set('name', res.data.name)
          wsCache.set('seat_name', res.data.seat_name)
          wsCache.set('admin_id', res.data.admin_id)
          wsCache.set('is_police', res.data.is_police)
          wsCache.set('is_manager', res.data.is_manager)
          wsCache.set('type', res.data.type)
          wsCache.set('latitude', res.data.latitude)
          wsCache.set('longitude', res.data.longitude)
          /*
          wsCache.set('token', res.token)
          wsCache.set('name', res.name)
          wsCache.set('seat_name', res.seat_name)
          wsCache.set('admin_id', res.admin_id)
          wsCache.set('is_police', res.is_police)
          wsCache.set('is_manager', res.is_manager)
          wsCache.set('latitude', res.latitude)
          wsCache.set('longitude', res.longitude)
           */

          this.$router.replace({name: 'Main'})
        }
      })
    }
  }
})
export default class Login extends Vue {}
